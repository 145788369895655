const CheckDevice = (() => {
  class Device {
    deviceType() {
      return window
        .getComputedStyle(document.querySelector('body'), '::before')
        .getPropertyValue('content').replace(/'/g, '').replace(/"/g, '');
    }

    checkDeviceType(MQ, isMobile, isDesktop, arrCbs) {
      if (MQ === 'desktop' && isDesktop) {
        arrCbs[0]();
      } else if (MQ === 'mobile' && isMobile) {
        arrCbs[1]();
      }
    }

    staticInit(mq, firstFunc, secFunc) {
      if (mq === 'desktop') {
        firstFunc();
      } else if (mq === 'mobile') {
        secFunc();
      }
    }
  }
  return Device;
})();
